import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Alignment,
  Icon,
  RAIcon,
  CloseIcon,
  BurgerIcon,
  SearchIcon,
  Box,
} from '@resident-advisor/design-system'
import { usePersonalizationContext } from 'context/PersonalizationContext'
import Link, { hrefAsPropTypes } from 'components/generic/link'
import dict from 'messages/dict'
import events from 'messages/events'
import styled, { css } from 'styled-components'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { generateUrlForAreaAndCountry } from 'lib/utils'
import buttonTrackingIds from 'tracking/button-tracking-ids'
import RAProHorizontal from 'public/static/ra-pro-logo-horizontal.svg'
import featureSwitches from 'enums/feature-switches'
import regex from 'lib/regex'
import {
  useFeatureSwitch,
  useFeatureSwitchData,
} from 'context/FeatureSwitchesContext'
import useIsMobile from 'hooks/useIsMobile'
import arrayHasData from 'lib/arrayHasData'
import { IVariant } from 'unleash-proxy-client'
import { actions, useMenuContext } from 'context/MenuContext'
import PLAYWRIGHT_TEST_IDS from 'enums/playwright-test-ids'
import { AnimatePresence } from 'framer-motion'
import Tracking, { TRACKING_EVENT } from 'scripts/tracking'
import sections from './sections'
import GlobaNavUserItem from './GlobalNavUserItem'
import hoverColorStyles from './hoverColorStyles'
import GlobalNavDropdown from './GlobalNavDropdown'
import NavLinkItem, { NavLinkItemType } from './NavLinkItem'

interface LinkPayload {
  name: string
  url: string
}

const showFeatureSwitchLink = (featureSwitch: {
  enabled: boolean
  variant: Partial<IVariant>
  payload: LinkPayload
}): boolean => {
  return (
    featureSwitch?.enabled &&
    featureSwitch?.variant?.name === 'on' &&
    featureSwitch?.payload?.name !== '' &&
    (regex.url.test(featureSwitch.payload.url) ||
      regex.relativeUrl.test(featureSwitch.payload.url))
  )
}

const GlobalNavBarNew = ({
  toggleShowSearch,
  toggleMenuSection,
}: GlobalNavBarNewProps) => {
  const intl = useIntl()
  const { loading, area: userArea, eventsArea } = usePersonalizationContext()
  const isMobile = useIsMobile()
  const [showNavDropdown, setShowNavDropdown] = useState(false)
  const [hoverItem, setHoverItem] = useState(null)
  const [offset, setOffset] = useState(0)
  const [{ showMobileMenu }, dispatch] = useMenuContext()
  const contentAreas = getContentAreas(loading, userArea, eventsArea)
  const { subSections } =
    contentAreas.find((area) => area.key === hoverItem) ?? {}
  const fourthLinkInGlobalNav = useFeatureSwitchData<LinkPayload>(
    featureSwitches.enableFourthLinkWithPayloadInGlobalNav
  )
  const showFourthLinkInGlobalNav = showFeatureSwitchLink(fourthLinkInGlobalNav)
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)
  const showUserMenu = useCallback(
    () => toggleMenuSection(sections.user),
    [toggleMenuSection]
  )

  useEffect(() => {
    const show = arrayHasData(
      contentAreas.find((area) => area.key === hoverItem)?.subSections
    )
    setShowNavDropdown(show)
  }, [contentAreas, hoverItem])

  const heightRef = useRef<HTMLDivElement>()

  useEffect(() => {
    dispatch({
      type: actions.SET_GLOBAL_NAV_HEIGHT,
      payload: heightRef?.current?.offsetHeight,
    })
  }, [dispatch])

  if (!enableNewMainNav) return null
  return (
    <div data-testid="new-global-nav">
      <Alignment
        display="flex"
        ref={heightRef}
        justifyContent="space-between"
        py={{ s: 2, m: 0 }}
        onMouseLeave={() => {
          setHoverItem(null)
        }}
      >
        <Alignment
          alignItems="center"
          justifyContent={{ s: 'space-between', m: undefined }}
          width={{ s: '100%', m: 'auto' }}
        >
          <GlobalNavLink href="/" hoverColor={isMobile ? 'white' : 'accent'}>
            <HoverIcon
              size={40}
              Component={RAIcon}
              mr={3}
              isMobile={isMobile}
            />
          </GlobalNavLink>
          <Alignment alignItems="center" display={{ s: 'none', m: 'flex' }}>
            {contentAreas.map(({ key, ...linkProps }) => (
              <Alignment
                key={key.id}
                flexDirection="column"
                ref={(el) => {
                  if (hoverItem === key) {
                    setOffset(el?.offsetLeft ?? 0)
                  }
                }}
              >
                <Alignment
                  onMouseEnter={() => {
                    setHoverItem(key)
                  }}
                  py={{ s: 0, m: 3 }}
                >
                  <NavLinkItem
                    navLinkItemType={NavLinkItemType.Standard}
                    text={intl.formatMessage(key)}
                    {...linkProps}
                  />
                </Alignment>
                <AnimatePresence exitBeforeEnter>
                  {showNavDropdown &&
                    !isMobile &&
                    hoverItem &&
                    arrayHasData(subSections) && (
                      <GlobalNavDropdown
                        offsetLeft={offset}
                        setHoverItem={setHoverItem}
                        dropdownSubSections={subSections}
                      />
                    )}
                </AnimatePresence>
              </Alignment>
            ))}
            {showFourthLinkInGlobalNav && (
              <Box
                display={{ s: 'none', m: 'block' }}
                py={{ s: 0, m: 3 }}
                onMouseEnter={() => setHoverItem(null)}
              >
                <NavLinkItem
                  navLinkItemType={NavLinkItemType.Standard}
                  href={fourthLinkInGlobalNav.payload.url}
                  text={fourthLinkInGlobalNav.payload.name}
                  onClick={() => {
                    Tracking.trackMixpanel(
                      TRACKING_EVENT.fourthNavLinkClicked,
                      {
                        'Nav Version': 'New',
                        'Link Clicked': fourthLinkInGlobalNav.payload.name,
                      }
                    )
                  }}
                />
              </Box>
            )}
          </Alignment>
          <HoverIcon
            color="primary"
            Component={SearchIcon}
            mx={2}
            isMobile={isMobile}
            onClick={toggleShowSearch}
            css={{ cursor: 'pointer' }}
            data-button-tracking-id={buttonTrackingIds.openNavSearchModal}
            data-pw-test-id={PLAYWRIGHT_TEST_IDS.searchIcon}
          />
        </Alignment>
        <Alignment justifyContent="end">
          <Alignment alignItems="center" display={{ s: 'none', m: 'flex' }}>
            <GlobaNavUserItem color="primary" onClick={showUserMenu} />
            <GlobalNavLink href="/pro">
              <HoverIcon
                mx={3}
                isMobile={isMobile}
                height={14}
                width={60}
                Component={RAProHorizontal}
                color="primary"
              />
            </GlobalNavLink>
          </Alignment>
          <Alignment alignItems="center" display={{ s: 'flex', m: 'none' }}>
            <HoverIcon
              color="primary"
              isMobile={isMobile}
              Component={showMobileMenu ? CloseIcon : BurgerIcon}
              css={{ cursor: 'pointer' }}
              onClick={() => dispatch({ type: actions.TOGGLE_MOBILE_MENU })}
              data-button-tracking-id={buttonTrackingIds.openNavModal}
              mx={2}
            />
          </Alignment>
        </Alignment>
      </Alignment>
    </div>
  )
}

const getContentAreas = (
  loading: boolean,
  userArea: number,
  eventsArea: number
): Section[] => {
  const areaToUse = eventsArea || userArea
  const urlForAreaAndCountry = generateUrlForAreaAndCountry(
    '/events',
    areaToUse
  )

  return [
    {
      key: dict.events,
      href: loading ? '/events' : urlForAreaAndCountry,
      subSections: [
        { key: events.forYou, href: `${urlForAreaAndCountry}/for-you` },
        { key: events.RAPicks, href: `${urlForAreaAndCountry}/ra-picks` },
        { key: events.festivals, href: '/festivals' },
      ],
    },
    {
      key: dict.music,
      href: '/music',
      subSections: [
        { key: dict.raPodcast, href: '/podcast' },
        { key: dict.reviews, href: '/reviews' },
        { key: dict.mixOfTheDay, href: '/mix-of-the-day' },
        { key: dict.playlists, href: '/playlists' },
      ],
    },
    {
      key: dict.magazine,
      href: '/magazine',
      subSections: [
        { key: dict.features, href: '/features' },
        { key: dict.films, href: '/films' },
        { key: dict.raExchange, href: '/exchange' },
        { key: dict.news, href: '/news' },
      ],
    },
  ]
}

type Section = {
  key: {
    id: string
    defaultMessage: string
  }

  href: string
  subSections: SubSections[]
}

type SubSections = {
  key: {
    id: string
    defaultMessage: string
  }

  href: string
}

const HoverIcon = styled(Icon)`
  ${({ isMobile }) =>
    !isMobile &&
    css`
      ${hoverColorStyles};
    `}
`

const GlobalNavLink = styled(Link)`
  outline: none;
`
type GlobalNavBarNewProps = {
  toggleShowSearch: () => null
  toggleMenuSection: (string) => null
}

GlobalNavLink.propTypes = {
  href: hrefAsPropTypes.isRequired,
  as: hrefAsPropTypes,
  children: PropTypes.node.isRequired,
}

export default GlobalNavBarNew
export { GlobalNavLink, showFeatureSwitchLink }
export type { LinkPayload }
